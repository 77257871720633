import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUpRight } from "@fortawesome/pro-solid-svg-icons/faArrowUpRight"
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight"

const SmallButton = ({ href, buttonText, destination, rightArrow }) => {
  const arrow = rightArrow ? faArrowRight : faArrowUpRight

  return (
    <Link to={href} title={buttonText} className="standard-button small">
      <span>{buttonText}</span>
      <FontAwesomeIcon icon={arrow} />
    </Link>
  )
}

export default SmallButton
