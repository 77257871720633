import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Heading } from "../components/Hero"
import Layout from "../components/layout"
import { Container, Section } from "../components/Layouts"
import SEO from "../components/seo"
import Portfolio from "../components/Portfolio/Portfolio"
import Video from "../components/Video/Video"
import Sizzle from "../videos/sizzle-home.mp4"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import { Parallax } from "react-parallax"
import SmallButton from "../components/Buttons/SmallButton"

const Homepage = ({ data, pageContext, location, path }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { language } = pageContext
  const {
    heroSection: { content, button },
    cta
  } = post

  return (
    <Layout
      className={"main-homepage"}
      language={language}
      isNavOverHero={true}
      path={path}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={post.schema ?? null}
        pathname={location.pathname}
        preload={
          <link
            rel="preload"
            as="image"
            href="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_auto/v1/NUVO/V2/homepage-hero-mobile-1"></link>
        }
      />
      <Heading markdown={content} />
      <Section zeroTop>
        <Container>
          <SmallButton href={button.href} buttonText={button.buttonText} />
        </Container>
      </Section>

      <Parallax
        renderLayer={percentage => (
          <div
            className="homepage__sizzle"
            style={{
              width: `${10 * percentage + 85}%`
            }}>
            <Video src={Sizzle} />{" "}
          </div>
        )}></Parallax>

      <Section zeroTop>
        <Portfolio listColor="white" />
      </Section>

      <Section className="homepage__cta">
        <Container>
          <MarkdownViewer markdown={`### ${cta.heading}`} />
          <div className="button-group">
            {cta.buttons.map(({ button }, i) => (
              <SmallButton
                key={button.href}
                href={button.href}
                buttonText={button.buttonText}
                appearance={button.appearance}
                destination={button.destination}
                rightArrow={i > 0}
              />
            ))}
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

Homepage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
  path: PropTypes.string,
  pageContext: PropTypes.object
}

export const pageQuery = graphql`
  query basicquery {
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        schema
        metaTitle
        metaDescription
        seoHeading
        heroSection {
          content
          button {
            buttonText
            href
            destination
            appearance
          }
        }
        cta {
          heading
          buttons {
            button {
              href
              appearance
              buttonText
              destination
            }
          }
        }
      }
    }
  }
`

export default Homepage
